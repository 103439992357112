


























import {Component, Prop, Vue} from 'vue-property-decorator';
import Job from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import moment, {Moment} from 'moment';
import CleanTimeOccurrence from '@/models/CleanTimeOccurrence';

@Component({
  components: {
    CalendarEventTile: () => import(
        '@/components/shared/CalendarEventTile.component.vue'),
  },
})
export default class CalendarViewDayComponent extends Vue {

  @Prop({default: () => []})
  public events!: Array<{ job: Job, userData: JobUserDataInterface[] }>;

  @Prop({default: () => moment()})
  public displayDate!: Moment;

  private INTERVAL_WIDTH = 60;
  private HOURS_PER_DAY = 24;

  private get nowX(): number {
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    return hours * this.INTERVAL_WIDTH + (minutes / 60 * this.INTERVAL_WIDTH);
  }

  /**
   * Extract the events from the array and push them to a new 2D one
   * @private
   */
  private get populateCalendar() {
    const populatedCalendar: Array<Array<{ job: Job, userData: JobUserDataInterface[] }>> = [[]];
    for (const value of this.events) {
      // If value is undefined, continue
      if (!value) {
        continue;
      }
      // If the start is the day before
      if (this.displayDate.startOf('day').isAfter(value.job.cleanTimeOccurrence.start)) {
        value.job.cleanTimeOccurrence = this.splitCleanTime(value.job.cleanTimeOccurrence, 1);
        // If the end is at the day after
      } else if (this.displayDate.endOf('day').isBefore(value.job.cleanTimeOccurrence.end)) {
        value.job.cleanTimeOccurrence = this.splitCleanTime(value.job.cleanTimeOccurrence, 0);
      }
      // calculate startMoment
      const startMoment = moment(value.job.cleanTimeOccurrence.start, 'HH:mm');
      const lastRow = populatedCalendar[populatedCalendar.length - 1];
      const lastEvent = lastRow[lastRow.length - 1];
      if (!lastEvent) {
        // populatedCalendar has no entries
        populatedCalendar[populatedCalendar.length - 1].push(value);
        continue;
      }
      const endMoment = moment(lastEvent.job.cleanTimeOccurrence.end, 'HH:mm');
      if (endMoment.isBefore(startMoment.format('HH:mm'))) {
        lastRow.push(value);
      } else {
        populatedCalendar.push([value]);
      }
    }
    return populatedCalendar;
  }

  private splitCleanTime(cleanTimeOccurrence: CleanTimeOccurrence, index: 0 | 1): CleanTimeOccurrence {
    // Get start and end moment
    const start = moment(cleanTimeOccurrence.start);
    const end = moment(cleanTimeOccurrence.end);
    // copy occurrence --> copy() does not work. Try in office again
    const startOccurrence = new CleanTimeOccurrence();
    startOccurrence.start = cleanTimeOccurrence.start;
    const endOccurrence = new CleanTimeOccurrence();
    endOccurrence.end = cleanTimeOccurrence.end;
    // set end and duration of first half
    startOccurrence.end = start.clone().endOf('day').toISOString();
    startOccurrence.duration = Math.abs(start.diff(startOccurrence.end, 'ms'));
    // set start and duration of second half
    endOccurrence.start = this.displayDate.startOf('day').toISOString();
    endOccurrence.duration = Math.abs(end.diff(endOccurrence.start, 'ms'));
    return [startOccurrence, endOccurrence][index];
  }

  /**
   * Calculate the x-Coord for the event in the Calendar
   * @param job
   * @param isLast Flag for LineBreak
   * @private
   */
  private calculateEventCoords(job: Job, isLast: boolean) {
    const hours = +moment(job.cleanTimeOccurrence.start).format('HH') * this.INTERVAL_WIDTH;
    const minutes = +moment(job.cleanTimeOccurrence.start).format('mm') / 60 * this.INTERVAL_WIDTH;
    return {
      left: hours + minutes + 'px',
      minWidth: this.calculateWidth(job.cleanTimeOccurrence.duration),
      maxWidth: this.calculateWidth(job.cleanTimeOccurrence.duration),
      position: isLast ? 'relative' : 'absolute',
    };
  }

  private calculateWidth(time: number) {
    return (time / 1000 / 60) + 'px';
  }

  private showEventDetails(event: any) {
    this.$emit('showDetails', event);
  }

  private isToday() {
    return this.displayDate.isSame(moment(), 'day');
  }
}
